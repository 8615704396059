import QRCode from "qrcode";
/**
 * 根据信息生成二维码
 * @param {string} url
 * @param {QRCodeToDataURLOptions} option
 */
export function mkQrcode(url, option = {}) {
    return new Promise((resolve, reject) => {
        if (!url) {
            reject();
        }
        QRCode.toDataURL(url, option, function(err, url) {
            if (err) {
                return reject();
            }
            resolve(url);
        });
    });
}
