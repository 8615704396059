<!--  -->
<template>
  <div class="content">
       <top-title>
      <span slot="left">订单支付</span>
      <span slot="right">订单支付</span>
    </top-title>
    <div class="data">
      <div class="inner">
        <el-steps :active="active" finish-status="success" simple>
          <el-step title="选择支付方式"> </el-step>
          <el-step title="支付成功"></el-step>
        </el-steps>
        <div class="pay-type" v-if="active == 1">
          <div style="width: 50%；text-align: center">
            <span style="color: #999; font-size: 17px; margin-right: 10px"
              >应收金额:</span
            >
            <span style="color: #0a64fa; font-size: 20px"
              >{{ orderInfo.orderPrice }}&nbsp;元</span
            >
          </div>
          <div style="margin-top: 20px">
            <el-button @click="payByType('wx')">微信支付</el-button>
            <el-button @click="payByType('ali')">支付宝支付</el-button>
          </div>
          <div v-if="payType == 'wx'">
            <div class="qrcode">
              <img :src="qrcodesrc" alt="二维码" />
            </div>
            <p class="wx-detail">
              <img src="@/assets/img/login_wx.png" />
              使用微信扫码付款
            </p>
          </div>
          <div v-if="payType == 'ali'" v-html="aliHtml"></div>
        </div>
        <div class="pay-type" v-else>
          <p class="wx-detail">
            <img src="@/assets/img/success.png" />
          </p>
          <p style="color: #0a64fa; font-size: 27px; margin-right: 10px">
            支付成功
          </p>
          <div style="width: 50%；text-align: center">
            <span style="color: black; font-size: 20px; margin-right: 10px"
              >金额:</span
            >
            <span style="color: black; font-size: 20px"
              >￥&nbsp;{{ orderInfo.orderPrice }}</span
            >
          </div>
          <div style="margin-top: 20px">
            <el-button @click="finish()">完成</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopTitle from "@/components/common/top-title";
import { orderInfo, wxPay, aliPay } from "@/api/order.js";
import { mkQrcode } from "@/common/utils.js";
export default {
  data() {
    return {
      memberId: "",
      active: 1,
      orderInfo: {},
      payType: "",
      payInfo: {},
      qrcodesrc: "",
      aliHtml: "",
      timer: null,
    };
  },
  created() {
    let orderId = this.$route.query.orderId;
    this.orderInfoHandler(orderId);
  },

  components: {
    TopTitle,
  },

  computed: {},

  methods: {
    //订单详情
    orderInfoHandler(id) {
      orderInfo(id).then((resp) => {
        if (resp.code == 0) {
          this.orderInfo = resp.data;
        } else {
          this.$message({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    //点击支付方式
    payByType(type) {
      this.payType = type;
      if ("wx" == type) {
        wxPay(this.orderInfo.orderSn).then((resp) => {
          if (resp.code == 0) {
            mkQrcode(resp.data.codeUrl).then((e) => {
              this.qrcodesrc = e;
            });
          } else {
            this.$message({
              message: resp.msg,
              type: "error",
            });
          }
        });
      } else if ("ali" == type) {
        aliPay(this.orderInfo.orderSn).then((resp) => {
          if (resp.code == 0) {
            this.aliHtml = resp.data.body;
            this.$nextTick(() => {
              const bestPayForm = document.getElementById("bestPayForm");
              if (bestPayForm) bestPayForm.submit();
            });
            window.onbeforeunload = null;
          } else {
            this.$message({
              message: resp.msg,
              type: "error",
            });
          }
        });
      }
      this.judgeStatus();
    },
    //轮询判断状态
    judgeStatus() {
      this.timer = setInterval(() => {
        orderInfo(this.orderInfo.id).then((resp) => {
          if (resp.code == 0) {
            this.orderInfo = resp.data;
            //已支付
            if (this.orderInfo.status == 2) {
              this.active = 2;
              clearInterval(this.timer);
            }
          }
        });
      }, 3000);
    },
    //完成
    finish() {
      this.$router.push({ name: "order" });
    },
  },
  beforeDestroy() {
    console.log("beforeDestroy");
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>
<style lang='scss' scoped>
.content {
  width: 100%;
  .data {
    padding: 20px;
    margin: 0 0 20px 0;
    background: #fff;
    // border: thin solid rgba(0, 0, 0, 0.12);
    .inner {
      .col-name {
        margin-right: 20px;
        color: #999;
        font-size: 14px;
        width: 7%;
      }
      .col-content {
        span {
          font-size: 14px;
          color: #666;
          font-weight: bold;
        }
      }
      .pay-type {
        width: 50%;
        margin: 37px auto;
        line-height: 50px;
        color: #777;
        font-size: 14px;
        text-align: center;
      }
    }
  }
}

em {
  position: relative;
  &.success {
    color: $success;
    &::before {
      content: "已认证";
    }
  }
  &.error {
    color: $danger;
    &::before {
      content: "未认证";
    }
  }
}

@media screen and (min-width: 992px) {
  .content {
    .part1 {
      .col-md9 {
        margin-bottom: 0;
      }
      .price {
        margin-right: 20px;
      }
    }
  }
}
</style>
